
import { Component, Vue } from 'vue-property-decorator'
import mainModule from '@/store/main'

const routeGuard = async (to: any, from: any, next: any) => {
  console.debug(`Route [${from.path}] => [${to.path}]`)
  await mainModule.actionCheckLoggedIn()

  if (to.matched.some((record: any) => record.meta.authRequired)) {
    const isAuthenticated = mainModule.isLoggedIn

    if (!isAuthenticated) {
      next({
        name: 'auth-login',
        query: { redirect: to.fullPath }
      })
      return
    }
  }
  next()
}

@Component
export default class Base extends Vue {
  public beforeRouteEnter(to: any, from: any, next: any) {
    routeGuard(to, from, next)
  }

  public beforeRouteUpdate(to: any, from: any, next: any) {
    routeGuard(to, from, next)
  }
}
